$().ready(function(){
	setupSlideMenu();
	if ($(".banners").length > 0) {
		goBanners();
	}
	$(".expander").click(function(){
		$(this).parent().toggleClass("active");
		if ($(this).parent().hasClass("active")){
			var elem = $(this).next(".content").clone().css({"height":"auto"}).insertAfter(this);
			var height = elem.css("height");
			elem.remove();
			$(this).next(".content").css("height",height);
		} else {
			$(this).next(".content").css("height", "0px");
		}
	});
	$("form").each(function(){
		$(this).addClass("infield-active").validate();
		$(".infield").inFieldLabels();
	});
});

function setupSlideMenu(){
	$("#mobile-menu, .slide-menu h3").click(function(){
		$("body").toggleClass("slide-menu-active");
	});
	$(".slide-menu").on("click",".expand",function(e) {
		e.stopPropagation();
		$(this).closest("li").toggleClass("expand-active");
	});
	$(".slide-menu li").each(function(index, element) {
		var $el = $(element);
		if ($el.find("ul").length > 0) {
			var expander = $("<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			$el.append(expander);
			expander.click(function(e) {
				e.stopPropagation();
				$el.toggleClass("expand-active");
			});
		}
	});
}

function goBanners(){
	if( $(".banners .banner").length > 1){
		setInterval(function(){swapBanner();}, 7000);
	}
}
function swapBanner(){
	var $oldb = $(".banners-home .banner.active");
	var $newb = $(".banners-home .banner.active").next();
	var $lastb = $(".banners-home .banner:last-of-type");

	if (! $lastb.hasClass("active")){
		$oldb.removeClass("active");
		$newb.addClass("active").find(".banner-name").addClass("animate");
		$oldb.find(".banner-name").removeClass("animate");

	} else {
		$oldb.removeClass("active");
		//Force layout calculation
		var h = $(".banners-home .banner:first-of-type").find(".banner-name .banner-item-content").height(); // eslint-disable-line no-unused-vars
		$(".banners-home .banner:first-of-type").addClass("active").find(".banner-name").addClass("animate");
		$oldb.find(".banner-name").removeClass("animate");
	}
}
